<!-- Login.vue -->
<template>
    <div class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-cream">
        <Link href="/" class="d-flex justify-content-center pb-3 mb-3">
          <img :src="'/img/Patrice Poltzer Best Story-88.png'" class="max-w-xs w-85">
        </Link>

        <div class="w-full sm:max-w-md mt-6 mx-3 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
            <h4 class="mb-5 color-primary">Login</h4>
            <form @submit.prevent="submit">
                <div>
                    <label class="block font-medium text-sm text-gray-700" for="email">
                        Email
                    </label>
                    <input v-model="form.email" id="email" type="email" class="mt-1 block w-full" required autofocus autocomplete="username">
                </div>

                <div class="mt-4">
                    <label class="block font-medium text-sm text-gray-700" for="password">
                        Password
                    </label>
                    <input v-model="form.password" id="password" type="password" class="mt-1 block w-full" required autocomplete="current-password">
                </div>

                <div class="block mt-4">
                    <label class="flex items-center">
                        <input type="checkbox" v-model="form.remember" class="form-checkbox">
                        <span class="ml-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>

                <div v-if="form.errors.auth" class="mt-2 text-red-500 text-sm">
                    {{ form.errors.auth }}
                </div>

                <div class="flex items-center justify-between mt-4">
                    <a v-if="canResetPassword" href="/forgot-password" class="underline text-sm text-gray-600 hover:text-gray-900">
                        Forgot your password?
                    </a>

                    <button class="ml-4 btn btn-primary" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Log in
                    </button>
                </div>

                <div class="mt-4 text-center">
                    <span class="text-sm text-gray-600">Don't have an account?</span>
                    <a :href="route('register')" class="ml-1 underline text-sm text-primary hover:text-red-600">Register now
                    </a>
                </div>
            </form>
        </div>
        <div class="mt-2">
            <a href="https://www.iubenda.com/privacy-policy/76058140" class="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed me-2" title="Privacy Policy">Privacy Policy</a> 
            <a href="https://www.iubenda.com/terms-and-conditions/76058140" class="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed ms-2" title="Terms and Conditions">Terms and Conditions</a>
        </div>
        <div>
            <h1 class="color-primary fw-bold mt-3 fs-2">My StoryPro AI</h1>
        </div>
    </div>
</template>

<script setup>
import { useForm, usePage, Link } from '@inertiajs/vue3';
import '../../../css/global.css';

const page = usePage();
//console.log('Page title prop:', page.props.title);

const props = defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => {
            form.reset('password');
        },
        preserveState: true,
    });
};
</script>

<style scoped>
.bg-cream {
    background-color: #F0ECDC;
}

.btn-primary {
    background-color: var(--color-red);
    color: white;
    font-family: 'Anton', sans-serif;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #B64023;
}

input[type="email"],
input[type="password"] {
    border: 1px solid #d8d8d8;
    background-color: white;
    color: var(--color-black);
    padding: 0.5rem;
    border-radius: 0.25rem;
}

input[type="email"]:focus,
input[type="password"]:focus {
    border: 1px solid #D4B769 !important;
}

input:focus {
    --tw-ring-color: none;
}

.form-checkbox {
    border: 1px solid #d8d8d8;
}

.form-checkbox:focus {
    --tw-ring-color: none;
}

.form-checkbox:checked {
    background-color: #D64F32;
    border-color: #D64F32;
}

input[type="email"]:focus,
input[type="password"]:focus {
    outline: none;
    border-color: var(--color-red);
}

.form-checkbox {
    color: var(--color-red);
    cursor: pointer;
}

a {
    color: #D64F32 !important;
    transition: color 0.3s ease;
}

a:hover {
    color: #d37f6c !important;
}
</style>